import(/* webpackMode: "eager" */ "/vercel/path0/blocks/Footer.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/blocks/ProjectTeaser.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["Gallery"] */ "/vercel/path0/blocks/skills/Gallery.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Testimonials"] */ "/vercel/path0/blocks/testimonials/Testimonials.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Giesskanne"] */ "/vercel/path0/components/Animation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BackToTopButton"] */ "/vercel/path0/components/BackToTopButton.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Button.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Container.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["DatoPreconnect"] */ "/vercel/path0/components/DatoPreconnect.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/FarbenmeerAndYou.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["NewsletterSingupForm"] */ "/vercel/path0/components/NewsletterSignupForm.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Overline.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Items.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/illustrations/du-bist-hier.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/illustrations/eyes.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/illustrations/footer-map.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/scribbles/authorized_signatory.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/scribbles/bonprix.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/scribbles/ceo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/scribbles/finanzcheck.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/scribbles/new_business.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/scribbles/project_management.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/scribbles/software_development.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/scribbles/success_assurance.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/scribbles/tolingo.svg");
